import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/sprzedaz-online.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    zdjecie1: file(relativePath: { eq: "pulpit-klienta-b2b.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie2: file(relativePath: { eq: "obsluga-zapytan-ofertowych.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie3: file(relativePath: { eq: "wielojezycznosc.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Przeznaczenieb2b = ({ data }) => {
  return (
    <Artykul
      title="System B2B - przeznaczenie i najważniejsze funkcje"
      keywords={["system b2b"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="System B2B - przeznaczenie i najważniejsze funkcje"
      metaTitle="System B2B - przeznaczenie i najważniejsze funkcje"
      metaDescription="System B2B - przeznaczenie i najważniejsze funkcje ✅ Kto powinien zainwestować w system 
      B2B? • Jakie są najważniejsze funkcje platformy B2B? • Video 2020 "
    >
      <p>
        Obecnie sprzedaż coraz częściej ma miejsce w Internecie. Klienci
        doceniają wygodę takich zakupów oraz możliwość przeglądania oferty i
        składania zamówień w dowolnym miejscu i czasie. To, co od dawna jest
        zauważalnym trendem w sprzedaży detalicznej, staje się również coraz
        powszechniejszym standardem na rynku B2B. Dlatego właśnie systemy B2B
        stają się coraz bardziej popularne. Wartość segmentu B2B każdego roku
        rośnie i wygląda na to, że ten trend będzie kontynuowany.
      </p>
      <ResponsiveEmbed
        src="https://www.youtube.com/embed/FjqEsztUNaA"
        allowFullScreen
        ratio="16:9"
      />
      <p>
        W badaniach rynkowych można wyodrębnić cztery przeważające trendy w
        sprzedaży B2B:
      </p>
      <p>
        <ul>
          <li>Profesjonalizacja w procesie sprzedaży.</li>
          <li>Optymalizacja struktury sprzedaży.</li>
          <li>Budowa oferty wartości.</li>
          <li>Cyfryzacja sprzedaży.</li>
        </ul>
      </p>
      <p>
        Tematem niniejszego odcinka będzie ostatni punkt, czyli przeniesienie do
        Internetu sprzedaży w modelu B2B.
      </p>
      <br />
      <h2>Powody dla wdrożenia systemu B2B</h2>
      <p>
        Możesz się zastanawiać - po co w mojej firmie taka platforma, skoro w
        tej chwili radzimy sobie bez niej? Zamówienia spływają obecnie
        tradycyjnymi kanałami - mailowo, telefonicznie i za pośrednictwem
        handlowców. Co można uzyskać zmieniając taki stan rzeczy?
      </p>
      <p>Wdrożenie dobrego systemu B2B wiąże się z istotnymi korzyściami:</p>
      <p>
        <ul>
          <li>
            <strong>budowanie konkurencyjności firmy</strong> w procesie
            sprzedażowym,
          </li>
          <li>
            <strong>zautomatyzowanie</strong> wybranych działań pracowników,
          </li>
          <li>
            <strong>szybsza realizacja zamówień</strong>,
          </li>
          <li>
            <strong>oszczędność czasu</strong> handlowców,
          </li>
          <li>
            <strong>obniżenie kosztów</strong> obsługi klienta.
          </li>
        </ul>
      </p>
      <p>
        System B2B to również ułatwienia i korzyści dla kontrahentów właściciela
        platformy. Zazwyczaj doceniają oni:
      </p>
      <p>
        <ul>
          <li>
            <strong>całodobową dostępność oferty</strong>,
          </li>
          <li>
            możliwość <strong>zdalnego składania zamówień</strong>,
          </li>
          <li>
            <strong>nieograniczony czas na zapoznanie się z ofertą</strong> i
            dokonanie wyboru.
          </li>
        </ul>
      </p>
      <br />
      <h2>Gdzie sprawdzi się system B2B?</h2>
      <p>Platforma sprzedażowa tego typu to idealne oprogramowanie dla:</p>
      <p>
        <ul>
          <li>
            <strong>firm produkcyjnych</strong>, które handlują z siecią
            dystrybutorów,
          </li>
          <li>
            <strong>dystrybutorów</strong> obsługujących odbiorców biznesowych,
          </li>
          <li>
            <strong>hurtowników</strong>, których oferta skierowana jest do
            podmiotów tworzących łańcuch dostaw.
          </li>
        </ul>
      </p>
      <p>
        Obecnie niemal każda branża sprzedająca fizyczne produkty może dopasować
        platformę B2B do swoich działań i czerpać z niej wymienione wyżej
        korzyści.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz bezpłatnie przetestować funkcje systemu do sprzedaży B2B?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami już teraz i uzyskaj dostęp do darmowej 30-dniowej wersji próbnej systemu do sprzedaży&nbsp;B2B

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę poznać funkcje systemu do sprzedaży B2B
            </Link>
          </div>
          </div>
    </section>
      <br />
      <h2>Najbardziej przydatne funkcje systemu B2B</h2>
      <p>
        Każda firma chcąca rozpocząć sprzedaż w Internecie może mieć nieco inne
        procesy i potrzeby. Oznacza to, że dla każdej z nich system B2B
        przybierze nieco inną formę. Można jednak bez trudu wskazać dziesięć
        najbardziej przydatnych funkcji platformy B2B, z których warto
        skorzystać właściwie w każdym przypadku.
      </p>
      <p>
        <strong>1.</strong> Podstawową funkcjonalnością systemu B2B jest
        możliwość prowadzenia{" "}
        <strong>sprzedaży hurtowej w trybie online</strong>. Klient może
        przejrzeć ofertę Twojego przedsiębiorstwa w dowolnym miejscu i czasie.
        Warto, aby proces składania zamówienia był dla niego jak najprostszy -
        dlatego zadbaj o:
      </p>
      <p>
        <ul>
          <li>wyczerpujące opisy produktów,</li>
          <li>ciekawe zdjęcia,</li>
          <li>funkcjonalną wyszukiwarkę produktów,</li>
          <li>opcję wyboru widoku oferty produktowej,</li>
          <li>koszyk, który zapisuje w pamięci dodane produkty.</li>
        </ul>
      </p>
      <p>
        W przypadku pytań dotyczących specyfikacji produktu, klient nie musi
        kontaktować się z opiekunem handlowym, gdyż takie dane znajdują się w
        kartotece produktu. Gdy zaś sprzedawane przedmioty występują w wielu
        konfiguracjach, możemy przygotować konfigurator produktu, dzięki któremu
        kontrahenci będą mogli samodzielnie dobrać interesujące ich elementy.
      </p>
      <p>
        <strong>2. Spersonalizowany pulpit klienta</strong> - czyli pierwsze
        miejsce, które widzi Twój klient po zalogowaniu się do platformy. To
        dobra okazja do tego, by wyświetlić mu tutaj produkty indywidualnie
        dopasowane do jego preferencji. W tym miejscu sprawdzają się również
        sekcje z informacjami o:
      </p>
      <p>
        <ul>
          <li>rekomendowanych produktach,</li>
          <li>promocjach,</li>
          <li>limicie kupieckim,</li>
          <li>statusie złożonych przez klienta zamówień.</li>
        </ul>
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 1200,
          margin: "0 auto",
        }}
        images={[data.zdjecie1]}
        alts={["Platforma B2B - panel klienta"]}
      />
      <br />
      <p>
        <strong>3. Integracja z systemem handlowym</strong> - bardzo istotne
        jest to, aby platforma B2B odzwierciedlała bieżącą ofertę firmy i jej
        aktualne stany magazynowe. Informacje o produktach, cenach, stanach
        magazynowych, fakturach i płatnościach pobierane są do niej wprost z
        systemu handlowo - magazynowego. W drugą stronę przesyłane są
        zamówienia.
      </p>
      <p>
        <strong>4. Zapytania ofertowe i negocjacje cen.</strong> Klient bez
        konieczności bezpośredniego kontaktu z handlowcem może w dowolnym
        momencie złożyć zapytanie ofertowe, które będzie później rozpatrzone w
        systemie B2B. W&nbsp;ten sam sposób kontrahent może negocjować cenę.
        Zaczyna od złożenia oferty, następnie handlowiec sprawdza, czy mieści
        się ona w progu cenowym akceptowanym przez firmę i przyjmuje ją lub
        odrzuca.
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 1200,
          margin: "0 auto",
        }}
        images={[data.zdjecie2]}
        alts={["Platforma B2B - składanie zapytania ofertowego"]}
      />
      <br />
      <p>
        <strong>5. Dostęp do faktur i rozliczeń.</strong> To niewątpliwie duże
        ułatwienie dla klienta, który w każdej chwili może przejrzeć poprzednie
        transakcje i otrzymane od nas dokumenty finansowe. A w razie jakichś
        wątpliwości - zawsze może skontaktować się z opiekunem.
      </p>
      <p>
        <strong>6. Zamówienia handlowców</strong> - to funkcjonalność pomagająca
        pracownikom działającym poza biurem. Jeśli sprzedawca wybiera się na
        spotkanie z klientem i chce w jego imieniu złożyć zamówienie, użyje w
        tym celu właśnie tego narzędzia. Funkcja ta przydaje się również w
        przypadku tych klientów, którzy nie chcą odejść od tradycyjnego modelu
        składania zamówień mailem lub telefonicznie.
      </p>
      <p>
        <strong>7. Ofertowanie klienta końcowego</strong> jest funkcją
        przeznaczoną dla tych kontrahentów, którzy odsprzedają produkty dalej i
        dlatego tworzą oferty własnym klientom. Ofertowanie takie jest bardzo
        proste - kontrahent wybiera interesujące go produkty, narzuca swoją
        marżę i generuje plik PDF.
      </p>
      <p>
        <strong>8. Wielowalutowość i wielojęzyczność</strong> - bardzo przydatna
        funkcja, jeżeli pośród Twoich klientów znajdują się również firmy
        zagraniczne. Możesz udostępnić im system B2B w wielu wersjach językowych
        oraz umożliwić wybór najwygodniejszej dla nich waluty.
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 1200,
          margin: "0 auto",
        }}
        images={[data.zdjecie3]}
        alts={["Platforma B2B - wielojęzyczność w systemie"]}
      />
      <p>
        <strong>9. Powiadomienia e-mail</strong> - to funkcja, dzięki której
        możemy automatycznie informować klienta lub pracownika o każdym ważnym
        wydarzeniu. Kontrahent może otrzymać powiadomienie na przykład o nowej
        fakturze lub rozpatrzeniu zapytania ofertowego. Pracownik natomiast może
        być powiadomiony o pojawieniu się nowego zamówienia lub zapytania.
      </p>
      <p>
        <strong>10. Ogłoszenia, promocje, newslettery</strong> - komunikacja z
        klientami nigdy nie może być traktowana po macoszemu. Choć platforma B2B
        powinna funkcjonować jak najbardziej samoobsługowo, warto czasem
        przekazywać kontrahentom najważniejsze informacje, nagradzać wybrane
        grupy klientów promocjami i&nbsp;regularnie wysyłać newslettery
        wszystkim zainteresowanym użytkownikom.
      </p>
<section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz bezpłatnie przetestować funkcje systemu do sprzedaży B2B?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Skontaktuj się z nami już teraz i uzyskaj dostęp do darmowej 30-dniowej wersji próbnej systemu do sprzedaży&nbsp;B2B

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę poznać funkcje systemu do sprzedaży B2B
            </Link>
          </div>
          </div>
    </section>
      <br />
      <br />
      <p>Warto przeczytać:</p>
      <p>
        <ul>
          <li>
            <Link to="/czym-jest-platforma-b2b/">
              Co to jest platforma B2B?
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Przeznaczenieb2b;
